import Base = require("Everlaw/Base");
import { DbCreditUsage } from "Everlaw/LLM/DbCreditUsage";
import { OrgCreditSettings } from "Everlaw/LLM/OrgCreditSettings";
import { Organization } from "Everlaw/Organization";
import { DatabaseSuspensionState } from "Everlaw/ScheduledDatabaseSuspensionJob";
import { Feature } from "Everlaw/FeatureFlag/Feature";
import Arr = require("Everlaw/Core/Arr");
import { capitalize } from "Everlaw/Core/Str";
import { ColorTokens } from "design-system";
import Dialog = require("Everlaw/UI/Dialog");
import { AdminSuspensionState } from "Everlaw/AdminSuspension/Constants";
import { BillingMode } from "Everlaw/BillingUtil";
import Database = require("Everlaw/Database");
import DatabaseField = require("Everlaw/DatabaseField/DatabaseField");
import { DateDisplayFormat, TimeDisplayFormat } from "Everlaw/DateUtil";
import { TranslationProvider, ProviderDefault, LlmSummaryLength } from "Everlaw/ServiceProvider";
import Dom = require("Everlaw/Dom");
import Language = require("Everlaw/Language");
import Partner = require("Everlaw/Partner");
import QueryDialog = require("Everlaw/UI/QueryDialog");
import Rest = require("Everlaw/Rest");
import { objectToQuery } from "Everlaw/Core/Obj";
import { MinimalOrganization, OrganizationId } from "Everlaw/MinimalOrganization";
import { wrapReactComponent } from "Everlaw/UI/ReactWidget";

class Project extends Base.SecuredObject {
    get className() {
        return "Project";
    }
    override id: Project.Id;
    parcel: Parcel;
    /**
     * Generally, either display() or partialName() should be used to display the project name,
     * rather than using this directly.
     */
    name: string;
    databaseId: number;
    databaseName: string;
    dbIsEmpty: boolean;
    deleted: boolean;
    suspended: boolean;
    deletionRequested: boolean;
    partial: boolean;
    productionMode: boolean;
    caseSizeCorrect: boolean;
    mfaRequired: boolean;
    // Front-end only: should this project be visible? Usually yes, but on the processing page you
    // can have projects on the front-end that you can't actually open.
    keepVisible = true;
    owningOrganizationId: OrganizationId;
    orgAdminAccess: boolean;
    allowOverlappingBates: boolean;
    billingMode: BillingMode;
    everlawDemo = false;
    context = Database.Context.EVERLAW;
    sbFreeOwner: number;
    useDefaultRedactionStampIfNeeded: boolean;
    hideMessageContentInEmails: boolean;
    eca: boolean;
    stripNativeAttachments: boolean;
    adminSuspensionState: string;
    genAiEnabled: boolean;
    constructor(params: any) {
        super(params);
        this._mixin(params);
    }
    override _mixin(params: any) {
        Object.assign(this, params);
        if (this.id === Project.getCurrentId()) {
            Project._mixinToCurrent(params);
        }
    }

    override display(): string {
        return Project.display(this.databaseName, this.name);
    }

    /**
     * Project name without database prefix (or just the database name if the default project).
     */
    partialName() {
        return this.isDefault() ? this.display() : this.name;
    }
    /**
     * Checks if this project is the last complete project in its database with partial projects.
     * Note that Base may not report all projects to a user, only the projects visible to them. Use
     * /parcel/<parcel>/getNumberOfDatabaseProjects.rest if you need to check for all projects.
     */
    lastCompleteWithPartials() {
        const otherDbProjects = Base.get(Project).filter(
            (p) => p.databaseId === this.databaseId && p.id !== this.id,
        );
        return (
            !this.partial
            && !otherDbProjects.some((p) => !p.partial)
            && otherDbProjects.some((p) => p.partial)
        );
    }
    rename(newname: string, callback: Rest.Callback, error?: Rest.Callback) {
        // We need to add the project to the url for when this is called from the org admin page.
        Rest.post(this.url("renameProject.rest"), { name: newname, projectId: this.id }).then(
            (data) => {
                Base.set(Project, data);
                callback && callback(data);
            },
            (e) => {
                error && error(e);
                throw e;
            },
        );
    }
    renameDatabase(
        newname: string,
        callback: Rest.Callback,
        error?: Rest.Callback,
        cancel?: (p: any) => void,
    ) {
        QueryDialog.create({
            title: "Rename database " + this.databaseName,
            prompt: `Are you sure you want to rename database ${this.databaseName} to ${newname}?`,
            onCancel: (p: any) => {
                cancel && cancel(p);
                return true;
            },
            onSubmit: () => {
                Rest.post(this.url("renameDatabase.rest"), { name: newname }).then(
                    (data) => {
                        Base.set(Project, data.projects);
                        Base.set(Database, data.database);
                        callback && callback(data);
                    },
                    (e) => {
                        error && error(e);
                        throw e;
                    },
                );
                return true;
            },
        });
    }
    getDatabase() {
        const db = Base.get(Database, this.databaseId);
        if (db === null) {
            throw Error("You can not access database at the current page.");
        }
        return db;
    }
    goto(page: string, hash?: any, newTab?: boolean) {
        window.open(
            this.urlFor(page, hash),
            newTab ? "_blank" : "_self",
            "resizable=yes, popup=false",
        );
    }
    urlFor(page: string, hash?: any) {
        return this.url(`${page}.do#${hash ? objectToQuery(hash) : ""}`);
    }
    /**
     * Fetches a map of users on this project. Each key is a User ID, and each value is a boolean
     * indicating whether that user is a project admin.
     */
    fetchUserIdMap(): Promise<{ [id: string]: boolean }> {
        return Rest.get(this.url("userMap.rest"));
    }
    fetchInvitationTokens(): Promise<string[]> {
        return Rest.get(this.url("invitationTokens.rest"));
    }
    url(url: string) {
        return Project.url(this.id, url);
    }
    static url(id: Project.Id, url: string): string {
        return "/" + id + "/" + url;
    }
    toggleSetting(setting: string, enabled?: boolean) {
        let flag: boolean;
        let flip: (enabled: boolean) => void;
        switch (setting) {
            case "productionMode":
                flag = this.productionMode;
                flip = (enabled) => {
                    this.productionMode = enabled;
                };
                break;
            case "smartPermGranting":
                // This should only be called on the current project (i.e., from the settings page).
                flag = Project.CURRENT.smartPermGranting;
                flip = (enabled) => {
                    Project.CURRENT.smartPermGranting = enabled;
                };
                break;
            case "mfaRequired":
                flag = this.mfaRequired;
                flip = (enabled) => {
                    this.mfaRequired = enabled;
                };
                break;
            case "caseSizeCorrect":
                flag = this.caseSizeCorrect;
                flip = (enabled) => {
                    Base.get(Project)
                        .filter((p) => p.databaseId === this.databaseId)
                        .forEach((project) => {
                            project.caseSizeCorrect = enabled;
                            Base.publish(project);
                        });
                };
                break;
            case "useDefaultRedactionStampIfNeeded":
                flag = this.useDefaultRedactionStampIfNeeded;
                flip = (enabled) => {
                    this.useDefaultRedactionStampIfNeeded = enabled;
                };
                break;
            case "hideMessageContentInEmails":
                flag = this.hideMessageContentInEmails;
                flip = (enabled) => {
                    this.hideMessageContentInEmails = enabled;
                };
                break;
            case "stripNativeAttachments":
                flag = this.stripNativeAttachments;
                flip = (enabled) => {
                    this.stripNativeAttachments = enabled;
                };
                break;
            default:
                // Invalid setting
                return;
        }
        // Make sure we have a boolean argument
        const target = enabled === !!enabled ? enabled : !flag;

        Rest.post(this.url(`${this.eca ? "eca/" : ""}toggleSetting.rest`), {
            target,
            setting,
        }).then(() => {
            flip(target);
            Base.publish(this);
        });
    }
    toggleProjectSuspension(suspend = !this.suspended, callback?: () => void) {
        Rest.get(`/parcel/${this.parcel}/getNumberOfDatabaseProjects.rest`, {
            databaseId: this.databaseId,
        })
            .then((data) => {
                // If we are suspending and this is the last complete project then we just suspend the database.
                if (data.numActiveAndComplete === 1 && suspend && !this.partial) {
                    this.suspendDatabase(callback);
                } else {
                    Rest.post(this.url("toggleProjectSuspension.rest"), { suspend }).then(
                        () => {
                            this.suspended = suspend;
                            Project.updateProjectStatuses([this], callback);
                        },
                        (error) => {
                            Dialog.ok("Error", [
                                Dom.div(error.message),
                                Dom.div("Please contact Everlaw support for more information."),
                            ]);
                        },
                    );
                }
            })
            .catch((response: Rest.Failed) => {
                response.show();
            });
    }

    suspendDatabase(callback?: () => void) {
        if (this.getDatabase().isScheduledForSuspension()) {
            this.cancelScheduledDatabaseSuspension();
        }
        const url = `/parcel/${this.parcel}/suspendDatabase.rest`;
        Rest.post(url, { databaseId: this.databaseId })
            .then((data) => {
                Project.updateProjectStatuses(data, callback);
            })
            .catch((error) => {
                Dialog.ok("Error", [
                    Dom.div(error.message),
                    Dom.div("Please contact Everlaw support for more information."),
                ]);
            });
    }

    scheduleDatabaseSuspension(callback?: () => void) {
        const url = `/parcel/${this.parcel}/scheduleDatabaseSuspension.rest`;
        Rest.post(url, { databaseId: this.databaseId })
            .then(() => {
                Database.updateDatabaseSuspensionState(
                    this.getDatabase(),
                    DatabaseSuspensionState.SCHEDULED,
                );
                callback && callback();
            })
            .catch((error) => {
                Dialog.ok("Error", [
                    Dom.div(error.message),
                    Dom.div("Please contact Everlaw support for more information."),
                ]);
            });
    }

    cancelScheduledDatabaseSuspension(callback?: () => void) {
        const url = `/parcel/${this.parcel}/cancelScheduledDatabaseSuspension.rest`;
        Rest.post(url, { databaseId: this.databaseId })
            .then(() => {
                Database.updateDatabaseSuspensionState(
                    this.getDatabase(),
                    DatabaseSuspensionState.NO_SUSPENSION,
                );
                callback && callback();
            })
            .catch((error) => {
                Dialog.ok("Error", [
                    Dom.div(error.message),
                    Dom.div("Please contact Everlaw support for more information."),
                ]);
            });
    }

    isDefault(): boolean {
        return Project.isDefault(this.name);
    }

    // Return if the project itself is administrative suspended.
    isAdminSuspended(): boolean {
        return this.adminSuspensionState === AdminSuspensionState.SUSPENDED;
    }
    // Return a boolean to decide if the project is impacted by an administrative suspension.
    // A project is under administrative suspension either itself or its parent objects are in such state.
    isImpactedByAdminSuspension(): boolean {
        const database = this.getDatabase();
        return this.isAdminSuspended() || (database && database.isImpactedByAdminSuspension());
    }
    inAdminSuspensionAnnouncement(): boolean {
        return this.adminSuspensionState === AdminSuspensionState.ANNOUNCED;
    }
    // Return a boolean to decide if the project is impacted by an administrative suspension announcement.
    isImpactedByAdminSuspensionAnnouncement(): boolean {
        const database = this.getDatabase();
        return (
            !this.isImpactedByAdminSuspension()
            && (this.inAdminSuspensionAnnouncement()
                || (database && database.isImpactedByAdminSuspensionAnnouncement()))
        );
    }
    showProjectSuspensionDialog(callback?: () => void) {
        let title: string;
        const display = this.display();
        const suspension = !this.suspended;
        const confirm = Dom.div({ id: "project-suspension-reactivation-prompt-confirm" });
        let submitText: string;
        const header = Dom.h5(
            { class: "margin-bottom-8" },
            `${suspension ? "Suspend" : "Reactivate"} ${display}?`,
        );
        const prompt = Dom.div({ id: "project-suspension-reactivation-prompt" }, header, confirm);
        const helpLink = Dom.a(
            {
                href: "https://support.everlaw.com/hc/en-us/articles/204991509-Suspension-and-Exporting-Options",
                target: "_blank",
                rel: "noopener noreferrer",
            },
            "suspension and exporting options",
        );
        if (suspension) {
            title = "Suspend project";
            const byline =
                "All documents, review data, and user analytics will remain but the project "
                + "will be inaccessible to all users. You can reactivate this project at any time.";
            Dom.setContent(confirm, byline);
            submitText = "Suspend";
            const helpInfo = Dom.div(
                { style: { color: ColorTokens.TEXT_SECONDARY, fontSize: "12px" } },
                Dom.div(
                    { style: { display: "inline" } },
                    "Database administrators will maintain access to database settings for this project. Learn more about ",
                ),
                Dom.span({ style: { color: ColorTokens.TEXT_LINK } }, helpLink),
                ".",
            );
            Dom.addContent(prompt, helpInfo);
        } else {
            title = "Reactivate project";
            const byline = "Users will be able to access this project once reactivated";
            Dom.setContent(confirm, byline);
            submitText = "Reactivate";
            const helpInfo = Dom.div(
                Dom.div(
                    { style: { color: ColorTokens.TEXT_SECONDARY, fontSize: "12px" } },
                    "Learn more about ",
                    Dom.span({ style: { color: ColorTokens.TEXT_LINK } }, helpLink),
                    ".",
                ),
            );
            Dom.addContent(prompt, Dom.div({ style: { color: ColorTokens.TEXT_LINK } }, helpInfo));
        }
        new QueryDialog({
            title,
            style: { width: "452px" },
            submitIsSafe: false,
            submitText,
            prompt,
            onSubmit: () => {
                this.toggleProjectSuspension(suspension, callback);
                return true;
            },
        }).show();
    }
}

module Project {
    export type Id = number & Base.Id<"Project">;

    export const DEFAULT_PROJECT_NAME = "(default)"; // This variable is duplicated in BaseVpcProject.java

    export function display(databaseName: string, projectName: string): string {
        return databaseName + (isDefault(projectName) ? "" : " — " + projectName);
    }

    export function isDefault(projectName: string): boolean {
        return projectName === Project.DEFAULT_PROJECT_NAME;
    }

    export enum status {
        NONPAYMENT_SUSPENSION = "NONPAYMENT SUSPENSION",
        SUSPENDED = "SUSPENDED",
        SUSPENSION_SCHEDULED = "SUSPENSION SCHEDULED",
        DELETION_REQUESTED = "DELETION REQUESTED",
        DELETED = "DELETED",
        NONPAYMENT_ANNOUNCEMENT = "ANNOUNCED",
        ACTIVE = "ACTIVE",
    }

    export namespace status {
        export function toDisplay(status: Project.status) {
            return capitalize(status.toLowerCase());
        }

        export function toDatabaseActionLabel(dbStatus: Project.status) {
            if (dbStatus === Project.status.ACTIVE) {
                return "Suspend database";
            } else if (dbStatus === Project.status.SUSPENDED) {
                return "Reactivate database";
            } else if (dbStatus === Project.status.SUSPENSION_SCHEDULED) {
                return "Manage suspension";
            } else {
                return "";
            }
        }
    }

    const DatabaseStatusHierarchy = {
        [Project.status.DELETED]: 0,
        [Project.status.DELETION_REQUESTED]: 1,
        [Project.status.SUSPENDED]: 2,
        [Project.status.SUSPENSION_SCHEDULED]: 3,
    };

    /**
     * Returns the current status of a database (deleted, deletion requested, suspended, or active)
     * based on the status of its child projects.
     */
    export function getDatabaseStatus(dbOrChildProjects: Database | Project[]): Project.status {
        let status = Project.status.DELETED;
        const projects =
            dbOrChildProjects instanceof Database
                ? Base.get(Project).filter((p) => p.databaseId === dbOrChildProjects.id)
                : dbOrChildProjects;

        if (projects.length === 0) {
            return status;
        }

        for (let i = 0; i < projects.length; i++) {
            const project = projects[i];
            if (
                project.deleted
                && DatabaseStatusHierarchy[Project.status.DELETED]
                    >= DatabaseStatusHierarchy[status]
            ) {
                status = Project.status.DELETED;
            } else if (
                project.deletionRequested
                && DatabaseStatusHierarchy[Project.status.DELETION_REQUESTED]
                    >= DatabaseStatusHierarchy[status]
            ) {
                status = Project.status.DELETION_REQUESTED;
            } else if (
                project.suspended
                && DatabaseStatusHierarchy[Project.status.SUSPENDED]
                    >= DatabaseStatusHierarchy[status]
            ) {
                status = Project.status.SUSPENDED;
            } else if (!project.suspended && !project.deleted && !project.deletionRequested) {
                status = Project.status.ACTIVE;
            }
        }

        const head = Arr.firstElement(projects, () => true);
        if (status === Project.status.ACTIVE && head.getDatabase().isScheduledForSuspension()) {
            status = Project.status.SUSPENSION_SCHEDULED;
        }

        return status;
    }

    export function keepVisible(p: Project) {
        return !p.deleted && p.id !== 0 && p.keepVisible;
    }

    export interface CurrentProject extends Project {
        emailDedupe: boolean;
        extractTextMetadata: boolean;
        smartPermGranting: boolean;
        timezoneId: string;
        reviewerQCStart: number;
        expectedLangs: Language[];
        translationProvider: TranslationProvider | ProviderDefault.NONE;
        dedupeSearches: boolean;
        uploadNotifications: boolean;
        productionCompletionNotifications: boolean;
        productionDownloadNotifications: boolean;
        additionalUploadRecipients: boolean;
        additionalProductionRecipients: boolean;
        uploadNotificationRecipients: string;
        productionCompletionNotificationRecipients: string;
        productionDownloadNotificationRecipients: string;
        rerunAdditionCutoff: number;
        partner: Partner;
        isSupportPartner: boolean;
        reviewCriteria: any[]; // EQL json
        defaultPageSize: string;
        dateDisplayFormat: DateDisplayFormat;
        timeDisplayFormat: TimeDisplayFormat;
        appendTimezone: boolean;
        metadataUploadTableValues: MetadataUploadTableValue[];
        // LLM features
        chronAutoSummarize: boolean;
        draftingAssistant: boolean;
        reviewAssistant: boolean;
        llmSummaryLength: LlmSummaryLength;
        caseDescription: string;
        codingAssistant: boolean;
        features: FeaturesInterface;
        // Undefined when not fetching in context of a single project page.
        nearDupeInclusionCriteria?: NearDupeInclusionCriteria;
        created: number;
    }

    /**
     * Used to describe how near duplicate groups are defined with respect to text similarity and
     * exact/email duplicate inclusion. See InclusionCriteria in NearDupeStatusMessage.java.
     */
    export enum NearDupeInclusionCriteria {
        ONLY_TEXT = "ONLY_TEXT",
        EXACT_AND_TEXT = "EXACT_AND_TEXT",
        EXACT_AND_EMAIL_AND_TEXT = "EXACT_AND_EMAIL_AND_TEXT",
    }

    type FeaturesInterface = {
        [key in Feature]: boolean;
    };

    /**
     * On project-specific pages, this holds the current project.
     */
    export let CURRENT: CurrentProject = null;

    export function getCurrentId() {
        return CURRENT ? CURRENT.id : null;
    }

    /**
     * Update full security on the current project.
     */
    export function updateFullSecurity(updated: Base.ACL) {
        if (updated) {
            Object.entries(updated).forEach(([sid, perms]) => {
                CURRENT.fullSecurity[sid] = perms;
            });
        }
        Base.publish(CURRENT);
    }

    export function setPageTitle(...title: string[]) {
        // Title is initialized by <title> element in headHeader; keep the two in the same style
        document.title = title.concat(CURRENT.display(), "Everlaw").join(" · ");
    }

    // Indicates whether all projects have been loaded. If false, only projects in Project.CURRENT's
    // database have been loaded.
    export let allLoaded = false;

    /**
     * Fetches json for all visible projects, and their databases and database fields. Loads them into
     * Base.
     */
    export function loadVisibleProjectsAndParentEntities(): Promise<void> {
        if (allLoaded) {
            return Promise.resolve();
        }
        return Rest.get("/getProjectsAndDatabasesAndOrgs.rest").then(
            (data: {
                projects: ({ id: number } & unknown)[];
                databases: ({ id: number } & unknown)[];
                organizations: {
                    full: ({ id: number } & unknown)[];
                    minimal: ({ id: number } & unknown)[];
                };
                fields: ({ id: number } & unknown)[];
            }) => {
                allLoaded = true;
                Base.set(Project, data.projects);
                Base.set(Database, data.databases);
                Base.set(Organization, data.organizations.full);
                Base.set(MinimalOrganization, data.organizations.minimal);
                Base.set(DatabaseField, data.fields);
            },
        );
    }

    export function _mixinToCurrent(params) {
        if (params.expectedLangs) {
            CURRENT.expectedLangs = Base.get(Language, <string[]>params.expectedLangs);
        }
        if (params.partner) {
            CURRENT.partner = Base.set(Partner, params.partner);
        }
    }

    export function updateMetadataTableValues(rows: MetadataUploadTableValue[]): void {
        // Make sure any extra keys beyond fieldName and typeName are not serialized.
        const rowData =
            rows.length === 0
                ? null
                : rows.map((r) => {
                      return { fieldName: r.fieldName, typeName: r.typeName };
                  });
        Rest.post("setMetadataUploadTableValues.rest", {
            rows: rowData ? JSON.stringify(rowData) : null,
        }).then(() => (CURRENT.metadataUploadTableValues = rowData || []));
    }

    export function displayById(projectId: Project.Id) {
        return getFromAll(projectId)?.display() || "Unknown";
    }

    /***
     * Show the project display and id, if available, or just the id if not.
     */
    export function adminDisplayById(projectId: Project.Id): string {
        const proj = getFromAll(projectId);
        return proj ? `${proj.display()} - ${projectId.toString()}` : projectId.toString();
    }

    export function getFromAll(projectId: Project.Id): Project {
        return (
            Base.get(Project, projectId)
            || Base.get("database settings projects", projectId)
            || Base.get("database settings deleted projects", projectId)
        );
    }

    export function updateProjectStatuses(data: Project[], callback?: () => void) {
        // When used with database settings, data may include spoofed projects that we don't want
        // displayed to the user in many cases. We can identify these projects by checking if the
        // projects include the sanitizedProject field set on the backend.
        Base.set(
            Project,
            data.filter((p: any) => !p.sanitizedProject),
        );
        // If we are working from database settings, go ahead and update all projects in the store.
        if (Base.get("database settings projects").length > 0) {
            for (const project of data) {
                Base.globalStore("database settings projects").add(new Project(project));
            }
        }
        callback && callback();
    }

    export interface MetadataUploadTableValue {
        fieldName: string;
        typeName: string;
    }

    /**
     * Return the default Project of the database, or if that doesn't exist, any complete project.
     * (A database should always have at least one complete project. It starts with the default project,
     * but that may be later deleted).
     */
    export function getDefaultProjectOrAnyComplete(databaseId: number): Project {
        const completeProjects = Base.get(Project)
            .filter((p) => p.databaseId === databaseId)
            .filter((p) => !p.partial);
        const defaultProject = completeProjects.filter((p) => p.isDefault())[0];
        return defaultProject || completeProjects[0];
    }
}

if (JSP_PARAMS.Project) {
    Base.set(Project, JSP_PARAMS.Project.projectsAndParentEntities.projects);
    Base.set(Database, JSP_PARAMS.Project.projectsAndParentEntities.databases);
    Base.set(Organization, JSP_PARAMS.Project.projectsAndParentEntities.organizations.full);
    Base.set(
        MinimalOrganization,
        JSP_PARAMS.Project.projectsAndParentEntities.organizations.minimal,
    );
    Base.set(DatabaseField, JSP_PARAMS.Project.projectsAndParentEntities.fields);
    Project.allLoaded = JSP_PARAMS.Project.allLoaded;
    const current = JSP_PARAMS.Project.current;
    if (current) {
        Project.CURRENT = <typeof Project.CURRENT>Base.set(Project, current);
        Project._mixinToCurrent(current);
    }
}

export = Project;
